import * as React from 'react'

import sendGaCustomEvent from '@/service/event-log/gtag-custom-event'

import type { ShareProps } from './type'

export default function useSendEventLog({
  gaCategory,
  gaAction,
  gaLabelPrefix,
}: Pick<ShareProps, 'gaCategory' | 'gaAction' | 'gaLabelPrefix'>): (platform: string) => void {
  return React.useCallback(
    (platform: string) => {
      if (gaCategory && gaAction) {
        sendGaCustomEvent({
          gaCategory,
          gaAction,
          gaLabel: gaLabelPrefix && `${gaLabelPrefix}-${platform}`,
        })
      }
    },
    [gaAction, gaCategory, gaLabelPrefix],
  )
}
