import * as React from 'react'

import Image from '@/components/common/image'

import StyledShareButton from './styled'

type Props = {
  onClick: () => void
  src: string
  alt: string
  name?: string
}

const ShareButton = ({ onClick, src, alt, name }: Props) => {
  return (
    <StyledShareButton type="button" onClick={onClick}>
      <div className="image">
        <Image src={src} alt={alt} layout="responsive" width={30} height={30} />
      </div>
      {name && <span>{name}</span>}
    </StyledShareButton>
  )
}

export default ShareButton
