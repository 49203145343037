import * as React from 'react'

import { NewsCompanyDetailFragment } from '@/gql/generated-api1'
import { useMobileSize } from '@/utils/browser/resize-hook'
import useLanguage from '@/utils/language/language-hook'

import { NewsListItemCrypto, NewsListItemStock } from '../news-list-item-asset'

import StyledNewsListItemAsset from './styled'

type Props = {
  hasMoreView?: boolean
  companyIds?: string[] | null
  cryptoCurrencies?: { symbol: string }[] | null
  stocksById: Record<string, NewsCompanyDetailFragment>
  cryptoPercentagesBySymbol: Record<string, number>
}

const assetsLimit = 8

const NewsListItemAssets = ({
  hasMoreView,
  companyIds,
  cryptoCurrencies,
  stocksById,
  cryptoPercentagesBySymbol,
}: Props) => {
  const { t } = useLanguage('common')
  const [isVisibleMoreView, setIsVisibleMoreView] = React.useState(!hasMoreView)
  const { isMobile } = useMobileSize()
  const companyIdsWithoutDuple = React.useMemo(
    () => Array.from(new Set(companyIds)).slice(0, assetsLimit),
    [companyIds],
  )
  const cryptoSymbolsWithoutDuple = React.useMemo(() => {
    // 중복되는 symbol 제거용(서버에서 코인 id가 같으면서 이름만 국제화로 다른 케이스를 별도로 내려주는 상황 대응)
    const cache: Record<string, true> = {}
    return (
      cryptoCurrencies
        ?.reduce((acc: string[], crypto) => {
          if (!cache[crypto.symbol]) {
            cache[crypto.symbol] = true
            acc.push(crypto.symbol)
          }
          return acc
        }, [])
        .slice(0, assetsLimit) || []
    )
  }, [cryptoCurrencies])
  const assetsLength = companyIdsWithoutDuple.length + cryptoSymbolsWithoutDuple.length

  return (
    <StyledNewsListItemAsset className={isVisibleMoreView ? '' : 'invisible'}>
      {companyIdsWithoutDuple.map((companyId) => (
        <React.Fragment key={companyId}>
          {stocksById[companyId] && <NewsListItemStock stock={stocksById[companyId]} />}
        </React.Fragment>
      ))}
      {cryptoSymbolsWithoutDuple.map((cryptoSymbol) => (
        <React.Fragment key={cryptoSymbol}>
          {typeof cryptoPercentagesBySymbol[cryptoSymbol] === 'number' && (
            <NewsListItemCrypto
              symbol={cryptoSymbol}
              changePercentage={cryptoPercentagesBySymbol[cryptoSymbol]}
            />
          )}
        </React.Fragment>
      ))}
      {hasMoreView && !isMobile && assetsLength > 2 && (
        <button
          className="--text-low-level more-view-button"
          type="button"
          onClick={() => setIsVisibleMoreView(!isVisibleMoreView)}>
          {isVisibleMoreView ? t('showLess') : `+${assetsLength - 2} ${t('showMore')}`}
        </button>
      )}
    </StyledNewsListItemAsset>
  )
}

export default NewsListItemAssets
