import * as React from 'react'

import { IMAGE_SERVER } from '@/constants/env'
import { useMobileSize } from '@/utils/browser/resize-hook'

type Props = {
  imageUrl?: string | null
}

const createNewsDefaultImageUrl = (n: number) => `${IMAGE_SERVER}/front/news/news-default-${n}.webp`
const defaultImagesUrls = [1, 2, 3, 4, 5].map((n) => createNewsDefaultImageUrl(n))

function getRandomNewsImage() {
  const number = Math.floor(Math.random() * 5) // 0 ~ 4
  return defaultImagesUrls[number]
}

export function useRandomNewsImage() {
  return React.useMemo(getRandomNewsImage, [])
}

export default function useNewsImage({ imageUrl }: Props) {
  const { isMobile } = useMobileSize()
  return React.useMemo(() => {
    if (!imageUrl && !isMobile) {
      return getRandomNewsImage()
    }

    return imageUrl
  }, [imageUrl, isMobile])
}
