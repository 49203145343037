import * as React from 'react'

import useLanguage from '@/utils/language/language-hook'
import sliceByBytes from '@/utils/string/slice-by-bytes'

import ShareButton from './share-button'

type Props = {
  onClick?: () => void
  logoOnly?: boolean
  shareUrl: string
  title?: string | null
  description?: string | null | (() => string)
}

const ShareTwitter = ({ title, description, shareUrl, onClick, logoOnly = true }: Props) => {
  const { t } = useLanguage('common')
  const handleClick = React.useCallback(() => {
    const desc = typeof description === 'function' ? description() : description
    const text = title ?? desc ?? ''
    const slicedText = sliceByBytes({ bytes: 280, value: text })

    window.open('https://twitter.com/intent/tweet?text=' + slicedText.value + '&url=' + shareUrl)

    onClick && onClick()
  }, [description, onClick, shareUrl, title])

  return (
    <ShareButton
      src="/images/shareTwitter@2x.png"
      alt="share Twitter"
      name={logoOnly ? t('twitter') : undefined}
      onClick={handleClick}
    />
  )
}

export default ShareTwitter
