import styled from 'styled-components'

const StyledShareButton = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  padding: var(--spacing-legacy-10);
  border-radius: var(--border-radius-12);
  width: 100%;

  :hover {
    background-color: var(--color-gray-32);
  }

  .image {
    width: var(--spacing-legacy-25);
    height: var(--spacing-legacy-25);
  }

  span {
    margin-left: var(--spacing-legacy-10);
  }
`

export default StyledShareButton
