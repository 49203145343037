type Props = {
  change: string | number
}

export default function priceChangeSign({ change }: Props) {
  if (+change > 0) {
    return '+'
  }

  return ''
}
