import * as React from 'react'
import Big from 'big.js'

import type { NewsFieldsFragment } from '@/gql/generated-news'
import { NewsCompanyDetailFragment, useCompanyDetailByIdsForNewsQuery } from '@/gql/generated-api1'

import useLocaleCurrency from '../currency/locale-currency-hook'
import useCurrencyExchanges from '../currency/currency-exchanges-hook'

type Props = {
  news: NewsFieldsFragment[]
}

// 전역 캐싱: 페이지네이션 하게되면 뉴스 개수가 지속적으로 증가해 한번에 요청하는 양이 많아져 느려질 수 있으므로 캐싱 처리
// 코인은 전역 스토어를 통해서 처리됨에 유의
const companyIdsCache: Record<string, NewsCompanyDetailFragment> = {}

export default function useNewsAssets({ news }: Props) {
  const { symbol: counter } = useLocaleCurrency()
  const { companyIds, cryptoSymbols } = React.useMemo(
    () =>
      news.reduce(
        (acc: { companyIds: string[]; cryptoSymbols: string[] }, newsItem, index) => {
          if (newsItem.companyIds?.length) {
            const filteredCompanyIds = newsItem.companyIds.filter(
              (companyId) => !companyIdsCache[companyId],
            ) // cache 필터링
            acc.companyIds = acc.companyIds.concat(filteredCompanyIds)
          }
          if (newsItem.cryptoCurrencies?.length) {
            acc.cryptoSymbols = acc.cryptoSymbols.concat(
              newsItem.cryptoCurrencies.map((crypto) => crypto.symbol),
            )
          }
          if (news.length - 1 === index) {
            acc.companyIds = Array.from(new Set(acc.companyIds))
            acc.cryptoSymbols = Array.from(new Set(acc.cryptoSymbols))
          }

          return acc
        },
        { companyIds: [], cryptoSymbols: [] },
      ),
    [news],
  )

  // 코인 가격 변동률 처리
  const cryptoPairs = React.useMemo(() => {
    return cryptoSymbols.map((crypto) => ({ base: crypto, counter }))
  }, [counter, cryptoSymbols])
  const { getCryptoCurrencyExchange } = useCurrencyExchanges({ cryptoPairs })

  // 주식 가격 변동률 처리: 별도 쿼리로 가격 데이터 요청
  const { data } = useCompanyDetailByIdsForNewsQuery({
    variables: { companyIds },
    skip: !companyIds || !companyIds.length,
  })

  const stocksById = React.useMemo(
    () =>
      data?.companyDetailByIds?.reduce((companyDetailByIds, stock) => {
        companyIdsCache[stock.id] = stock // 캐싱
        companyDetailByIds[stock.id] = stock
        return companyDetailByIds
      }, companyIdsCache) || companyIdsCache,
    [data?.companyDetailByIds],
  )

  const cryptoPercentagesBySymbol = React.useMemo(
    () =>
      cryptoSymbols.reduce((cryptoPercentageBySymbol: Record<string, number>, cryptoSymbol) => {
        const cryptoChangeRatio =
          getCryptoCurrencyExchange({
            base: cryptoSymbol,
            counter,
          })?.exchangePercent || 0
        cryptoPercentageBySymbol[cryptoSymbol] = +Big(cryptoChangeRatio).mul(100).toFixed(2)
        return cryptoPercentageBySymbol
      }, {}),
    [counter, cryptoSymbols, getCryptoCurrencyExchange],
  )

  return { stocksById, cryptoPercentagesBySymbol }
}
