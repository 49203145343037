import * as React from 'react'
import Tippy from '@tippyjs/react/headless'

import Image from '@/components/common/image'

import type { ShareProps } from '../type'
import ShareKakao from '../share-kakao'
import ShareFacebook from '../share-facebook'
import ShareTwitter from '../share-twitter'
import ShareURL from '../share-url'
import useSendEventLog from '../send-event-log-hook'

import StyledShareDropDown, { StyledShareDropDownList } from './styled'

const ShareDropDown = ({
  shareUrl,
  title,
  description,
  imageUrl,
  gaAction,
  gaCategory,
  gaLabelPrefix,
  children,
}: Omit<ShareProps, 'gaLabel'> & { children?: JSX.Element }) => {
  const sendEventLog = useSendEventLog({ gaAction, gaCategory, gaLabelPrefix })

  return (
    <StyledShareDropDown>
      <Tippy
        interactive
        trigger="click"
        placement="top-start"
        render={(_, __, instance) => (
          <StyledShareDropDownList className="--ani-slide-in">
            <li>
              <ShareKakao
                title={title}
                description={description}
                imageUrl={imageUrl}
                shareUrl={shareUrl}
                onClick={() => {
                  instance?.hide()
                  sendEventLog('KAKAOTALK')
                }}
              />
            </li>
            <li>
              <ShareFacebook
                shareUrl={shareUrl}
                onClick={() => {
                  instance?.hide()
                  sendEventLog('FACEBOOK')
                }}
              />
            </li>
            <li>
              <ShareTwitter
                title={title}
                description={description}
                shareUrl={shareUrl}
                onClick={() => {
                  instance?.hide()
                  sendEventLog('TWITTER')
                }}
              />
            </li>
            <li>
              <ShareURL
                shareUrl={shareUrl}
                onClick={() => {
                  instance?.hide()
                  sendEventLog('URL')
                }}
              />
            </li>
          </StyledShareDropDownList>
        )}>
        {children || (
          <button type="button" className="share-button" data-test-id="share-button">
            <Image
              src="/images/icons/share.svg"
              alt="share"
              layout="responsive"
              width={50}
              height={50}
            />
          </button>
        )}
      </Tippy>
    </StyledShareDropDown>
  )
}

export default ShareDropDown
