type Props = {
  bytes: number
  value: string
}

// 1 보다 큰 byte는 모두 2byte로 가정
// 한글은 utf-8에서 실제로 3byte로 계산됨에 유의
export default function sliceByBytes({ bytes, value }: Props): {
  value: string
  bytes: number
} {
  let prevAccBytes = 0
  let accBytes = 0
  let accValue = ''
  for (let index = 0; index < value.length; index++) {
    const oneValue = value[index]
    const size = new Blob([oneValue]).size
    prevAccBytes = accBytes
    accBytes += size > 1 ? 2 : size
    if (bytes < accBytes) {
      return { value: accValue, bytes: prevAccBytes }
    } else {
      accValue += oneValue
    }
  }

  return { value: accValue, bytes: prevAccBytes }
}
