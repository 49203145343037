import Script from 'next/script'

type Props = {
  onLoad?: () => void
}

/**
 * 카카오 공유와 로그인시 사용하는 카카오SDK 로드
 */
export default function KakaoSDK({ onLoad }: Props) {
  return (
    <Script
      id="kakao-sdk"
      src="https://developers.kakao.com/sdk/js/kakao.min.js"
      onLoad={() => {
        if (!window.Kakao.isInitialized()) {
          window.Kakao.init(process.env.NEXT_PUBLIC_KAKAO_LOGIN_KEY as string)
          onLoad && onLoad()
        }
      }}
    />
  )
}
