import * as React from 'react'

import useLanguage from '@/utils/language/language-hook'
import shareAsKakaoTalk from '@/utils/share-platform/share-as-kakaotalk'
import KakaoSDK from '@/components/third-party/kakao-sdk'

import ShareButton from './share-button'

type Props = {
  onClick?: () => void
  logoOnly?: boolean
  shareUrl: string
  title?: string | null
  description?: string | null | (() => string)
  imageUrl?: string | null
}

const ShareKakao = ({
  title,
  description,
  imageUrl,
  shareUrl,
  onClick,
  logoOnly = true,
}: Props) => {
  const { t } = useLanguage('common')
  const [loadScript, setLoadScript] = React.useState(false)
  const handleShare = React.useCallback(() => {
    if (window.Kakao && imageUrl && title && description) {
      shareAsKakaoTalk({
        title,
        description: typeof description === 'function' ? description() : description,
        imageUrl,
        shareUrl,
        buttonTitle: t('readMore'),
      })
    }
    onClick && onClick()
  }, [description, imageUrl, onClick, shareUrl, t, title])

  const handleClick = React.useCallback(() => {
    if (!window.Kakao) {
      setLoadScript(true)
      return
    }

    handleShare()
  }, [handleShare])

  return (
    <>
      <ShareButton
        src="/images/shareKakao.png"
        alt="share KakaoTalk"
        name={logoOnly ? t('kakaoTalk') : undefined}
        onClick={handleClick}
      />
      {loadScript && <KakaoSDK onLoad={handleShare} />}
    </>
  )
}

export default ShareKakao
