import styled from 'styled-components'

const StyledNewsRecommendButton = styled.button`
  border-radius: var(--border-radius-16);
  padding: var(--spacing-legacy-8) var(--spacing-legacy-10);
  font-size: var(--font-size-13);
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  background-color: var(--color-gray-32);
  border: 1px solid var(--color-gray-27);
  color: var(--color-gray-197);

  @media (hover: hover) and (pointer: fine) {
    :hover {
      border: 1px solid var(--color-primary-a2);
    }
  }

  &.active {
    background-color: var(--color-primary-a1);
    color: var(--color-primary);
    border: 1px solid var(--color-primary-a2);
  }
`

export default StyledNewsRecommendButton
