import * as React from 'react'

import { ExternalLink } from '@/components/common/link'

type Props = {
  children: React.ReactNode
  href: string
  onClick: () => void
}

const NewsDetailLink = ({ children, href, onClick }: Props) => {
  return (
    <ExternalLink
      onClick={onClick}
      href={href || '#'}
      target="_blank"
      rel="noopener noreferrer nofollow"
      gaCategory="NEWS"
      gaAction="NEWS_ITEM_CLICK"
      gaLabel={`NEWS_ITEM_${href}`}
      // href={`${location.pathname}?id=${newsId}` || '#'}
      // scroll={false}
    >
      {children}
    </ExternalLink>
  )
}

export default NewsDetailLink
