import * as React from 'react'
import Big from 'big.js'

import type { NewsCompanyDetailFragment } from '@/gql/generated-api1'
import Link from '@/components/common/link'
import { useGetByLocaleName } from '@/utils/language'
import useLocaleCurrency from '@/service/currency/locale-currency-hook'
import threeComma from '@/utils/number/three-comma'
import priceChangeColor from '@/utils/exchange/price-change-style'
import priceChangeSign from '@/utils/exchange/price-change-sign'

import StyledNewsListItemAsset from './styled'

type Props = {
  href: string
  mark: string
  changePercentage: number
}

const NewsListItemAsset = ({ href, mark, changePercentage }: Props) => {
  const changeStyle = React.useMemo(() => {
    return priceChangeColor({ priceRatio: changePercentage })
  }, [changePercentage])
  const formattedChange = React.useMemo(() => {
    const sign = priceChangeSign({ change: changePercentage })
    return `${sign}${threeComma({ value: changePercentage, fixed: 2 })}%`
  }, [changePercentage])
  return (
    <StyledNewsListItemAsset style={changeStyle} className="--ani-slide-in news-asset">
      <Link href={href} className="--flex-x-center">
        <span className="mark">{mark}</span>
        <span className="change">{formattedChange}</span>
      </Link>
    </StyledNewsListItemAsset>
  )
}

export const NewsListItemStock = ({ stock }: { stock: NewsCompanyDetailFragment }) => {
  const getByLocaleName = useGetByLocaleName()
  const changePercentage = React.useMemo(
    () =>
      +Big(stock.companyPrice?.priceChangePercent || 0)
        .mul(100)
        .toFixed(2),
    [stock.companyPrice?.priceChangePercent],
  )
  return (
    <NewsListItemAsset
      href={`/stock?ticker=${stock.ticker}&country=${stock.country}`}
      mark={getByLocaleName(stock.companyName)}
      changePercentage={changePercentage}
    />
  )
}

export const NewsListItemCrypto = ({
  symbol,
  changePercentage,
}: {
  symbol: string
  changePercentage: number
}) => {
  const { symbol: counter } = useLocaleCurrency()
  return (
    <NewsListItemAsset
      href={`/crypto?base=${symbol}&counter=${counter}`}
      mark={symbol}
      changePercentage={changePercentage}
    />
  )
}
