import * as React from 'react'

import type { NewsFieldsFragment } from '@/gql/generated-news'
import { useAuthTokenStore } from '@/service/auth/store'
import useClassName from '@/utils/array/class-name-hook'
import useToggleRecommend from '@/gql/news/toggle-recommend-hook'
import useLanguage from '@/utils/language/language-hook'
import sendGaCustomEvent from '@/service/event-log/gtag-custom-event'
import { openSigninModalWithToast } from '@/service/sign-in/store'

import StyledNewsRecommendButton from './styled'

type Props = {
  news: NewsFieldsFragment
}

const NewsRecommendButton = ({ news }: Props) => {
  const { userId } = useAuthTokenStore()
  const { t } = useLanguage('news')
  const recommendedCount = news.recommendedCount || 0
  const isRecommended = React.useMemo(
    () => !!news.recommendedUserIds?.includes(userId),
    [news.recommendedUserIds, userId],
  )
  const { toggle } = useToggleRecommend({ news, userId, isRecommended, recommendedCount })
  const className = useClassName([isRecommended && 'active'])
  const handleClick = React.useCallback(() => {
    sendGaCustomEvent({
      gaCategory: 'NEWS',
      gaAction: 'NEWS_ITEM-RECOMMEND_CLICK',
      gaLabel: `NEWS_ITEM-RECOMMEND_${news.id}`,
    })
    openSigninModalWithToast(() => {
      toggle()
    })
  }, [news.id, toggle])

  return (
    <StyledNewsRecommendButton className={className} onClick={handleClick}>
      {t('recommend')} {recommendedCount}
    </StyledNewsRecommendButton>
  )
}

export default NewsRecommendButton
