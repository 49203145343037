import * as React from 'react'

import type { NewsCompanyDetailFragment } from '@/gql/generated-api1'
import { NewsFieldsFragment } from '@/gql/generated-news'
import { useGetByLocaleName } from '@/utils/language'
import timeSince from '@/utils/date/time-since'
import useLanguage from '@/utils/language/language-hook'
import Skeleton from '@/components/common/skeleton'
import { useMobileSize } from '@/utils/browser/resize-hook'
import useNewsImage from '@/service/news/news-image-hook'

import NewsListItemAssets from '../news-list-item-assets'
import NewsDetailLink from '../news-detail-link'
import NewsRecommendButton from '../news-recommend-button'
import NewsShareButton from '../news-share-button'
import NewsListImage from '../news-list-image'

import StyledNewsListItem, { StyledFlatOnlyNewsListItem } from './styled'

type Props = {
  hasDesktopStyle?: boolean
  news: NewsFieldsFragment
  stocksById: Record<string, NewsCompanyDetailFragment>
  cryptoPercentagesBySymbol: Record<string, number>
  onNewsClick?: (newsId: string) => void
}

const NewsListItem = ({
  news,
  stocksById,
  cryptoPercentagesBySymbol,
  hasDesktopStyle,
  onNewsClick,
}: Props) => {
  const { language } = useLanguage()
  const getByLocaleName = useGetByLocaleName()
  const imageUrl = useNewsImage({ imageUrl: news.thumbnailUrl })

  const StyledContainer = React.useMemo(
    () => (hasDesktopStyle ? StyledNewsListItem : StyledFlatOnlyNewsListItem),
    [hasDesktopStyle],
  )
  const handleClick = React.useCallback(
    () => onNewsClick && onNewsClick(news.id),
    [news.id, onNewsClick],
  )

  return (
    <StyledContainer>
      <div className="text">
        <NewsDetailLink href={news.link} onClick={handleClick}>
          <h3 className="--line-truncate title">{getByLocaleName(news.title)}</h3>
          <div className="sub-text">
            <span>{getByLocaleName(news.publisher)}</span>
            {!!getByLocaleName(news.publisher) && !!news.pubDate && (
              <span className="separator">|</span>
            )}
            <span suppressHydrationWarning>
              {timeSince(new Date(news.pubDate as string), language)}
            </span>
          </div>
        </NewsDetailLink>
        <NewsListItemAssets
          companyIds={news.companyIds}
          cryptoCurrencies={news.cryptoCurrencies}
          stocksById={stocksById}
          cryptoPercentagesBySymbol={cryptoPercentagesBySymbol}
          hasMoreView={hasDesktopStyle}
        />
        <div className="action-buttons">
          <NewsRecommendButton news={news} />
          <NewsShareButton news={news} />
        </div>
      </div>

      {/* 없을 경우 텍스트가 넓어져야 하기 때문에 전체를 분기 렌더 */}
      {imageUrl && (
        <div className="--flex-shrink-off image">
          <NewsDetailLink href={news.link} onClick={handleClick}>
            <NewsListImage imageUrl={imageUrl} title={getByLocaleName(news.title)} />
          </NewsDetailLink>
        </div>
      )}
    </StyledContainer>
  )
}

export const NewsListItemSkeleton = ({ hasDesktopStyle }: { hasDesktopStyle: boolean }) => {
  const { isMobile } = useMobileSize()
  const StyledContainer = React.useMemo(
    () => (hasDesktopStyle ? StyledNewsListItem : StyledFlatOnlyNewsListItem),
    [hasDesktopStyle],
  )

  return (
    <StyledContainer>
      <div className="text">
        <div className="title">
          <Skeleton loading pcHeight={15} />
          <Skeleton loading pcWidth={100} pcHeight={15} />
        </div>
        <div className="sub-text">
          <Skeleton loading pcWidth={150} pcHeight={12} />
        </div>
      </div>
      <Skeleton
        className="image"
        loading
        mobileWidth={120}
        pcHeight={hasDesktopStyle && !isMobile ? undefined : 80}
        borderRadius="12px"
      />
    </StyledContainer>
  )
}

export default NewsListItem
