import styled from 'styled-components'

export const StyledShareDropDownList = styled.ul`
  background-color: var(--color-gray-27);
  box-shadow: var(--shadow-normal-blur-10);
  border-radius: var(--border-radius-12);
  padding: var(--spacing-legacy-8);
`

const StyledShareDropDown = styled.div`
  display: inline-block;

  .share-button {
    display: inline-block;
    width: var(--spacing-legacy-24);
    height: var(--spacing-legacy-24);
    vertical-align: top;
  }
`

export default StyledShareDropDown
