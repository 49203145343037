import styled from 'styled-components'

/**
 * 데탑 모발 너비 구분없이 항상 수직 리스트 형식으로 보여지는 경우
 */
export const StyledFlatOnlyNewsListItem = styled.div`
  display: flex;
  justify-content: space-between;

  > .text {
    flex-grow: 1;

    .title {
      color: var(--color-gray-197);
      font-size: var(--font-size-15);
      line-height: 1.4;
      -webkit-line-clamp: 2;
    }

    .sub-text {
      margin-top: var(--spacing-legacy-12);

      span {
        color: var(--color-gray-128);
        font-size: var(--spacing-legacy-13);
        word-break: break-word;
      }

      .separator {
        margin: 0 var(--spacing-legacy-5);
      }
    }

    .action-buttons {
      margin-top: var(--spacing-legacy-10);
      > * {
        :not(:last-child) {
          margin-right: var(--spacing-legacy-5);
        }
      }
    }
  }

  > .image {
    width: 32%;
    display: inline-block;
    margin-left: var(--spacing-legacy-15);

    img {
      object-fit: cover;
      border-radius: var(--border-radius-12);
      width: 100%;
      aspect-ratio: 3 / 2;
    }
  }
`

/**
 * 데탑 너비에서는 카드 아이템 그리드 리스트 레이아웃으로 보여지고
 * 모발 너비에서는 플랫 아이템 수직 리스트 레이아웃으로 보여지는 경우 사용
 */
const StyledNewsListItem = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }

  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
  }

  > .text {
    flex-grow: 1;

    .title {
      color: var(--color-gray-197);
      font-size: var(--font-size-15);
      line-height: 1.4;
      -webkit-line-clamp: 2;
    }

    .sub-text {
      margin-top: var(--spacing-legacy-12);

      span {
        color: var(--color-gray-128);
        font-size: var(--spacing-legacy-13);
        word-break: break-word;
      }

      .separator {
        margin: 0 var(--spacing-legacy-5);
      }
    }

    .action-buttons {
      margin-top: var(--spacing-legacy-10);
      > * {
        :not(:last-child) {
          margin-right: var(--spacing-legacy-5);
        }
      }
    }
  }

  > .image {
    @media (min-width: 768px) {
      position: relative;
      width: 100%;
      padding-top: 66%;
      margin-bottom: var(--spacing-legacy-20);
    }

    @media (max-width: 767px) {
      width: 32%;
      display: inline-block;
      margin-left: var(--spacing-legacy-15);
    }

    img {
      object-fit: cover;
      border-radius: var(--border-radius-12);

      @media (min-width: 768px) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }

      @media (max-width: 767px) {
        width: 100%;
        aspect-ratio: 3 / 2;
      }
    }
  }
`

export default StyledNewsListItem
