import {
  namedOperations,
  NewsFieldsFragment,
  NewsFieldsFragmentDoc,
  useToggleRecommendMutation,
} from '@/gql/generated-news'
import { newsServerApolloClient } from '@/apollo-client'

type Props = {
  news: NewsFieldsFragment
  userId: string
  isRecommended: boolean
  recommendedCount: number
}

export default function useToggleRecommend({
  news,
  userId,
  isRecommended,
  recommendedCount,
}: Props) {
  const [toggle] = useToggleRecommendMutation({
    variables: { newsId: news.id },
    client: newsServerApolloClient(),
    update(cache, { data }) {
      cache.updateFragment<NewsFieldsFragment>(
        {
          id: cache.identify(news),
          fragment: NewsFieldsFragmentDoc,
          fragmentName: namedOperations.Fragment.NewsFields,
        },
        (cachedData) => {
          if (cachedData) {
            return {
              ...cachedData,
              recommendedCount: data?.toggleRecommend?.recommendedCount || 0,
              recommendedUserIds: data?.toggleRecommend?.recommendedUserIds || [],
            }
          }
        },
      )
    },
    optimisticResponse: {
      toggleRecommend: {
        recommendedUserIds: isRecommended
          ? news.recommendedUserIds?.filter((userId) => !userId)
          : [...(news?.recommendedUserIds || []), userId],
        recommendedCount: isRecommended ? recommendedCount - 1 : recommendedCount + 1,
        __typename: 'News',
      },
    },
  })

  return { toggle, recommendedCount, isRecommended }
}
