import * as React from 'react'

import { useRandomNewsImage } from '@/service/news/news-image-hook'

type Props = {
  imageUrl: string
  title: string
}

const NewsListImage = ({ imageUrl, title }: Props) => {
  const randomNewsImage = useRandomNewsImage()
  return (
    <img
      src={imageUrl}
      alt={title}
      referrerPolicy="no-referrer"
      onError={(error) => {
        if (error.target) {
          ;(error.target as HTMLImageElement).src = randomNewsImage
        }
      }}
    />
  )
}

export default NewsListImage
