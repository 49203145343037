import React from 'react'

type Props = {
  priceRatio: string | number
}

export default function priceChangeColor({ priceRatio }: Props): React.CSSProperties {
  if (+priceRatio > 0) {
    return { color: 'var(--color-red-193)', background: 'var(--color-red-193-a17)' }
  }

  if (+priceRatio < 0) {
    return { color: 'var(--color-green-69)', background: 'var(--color-green-69-a17)' }
  }

  return { color: '--color-normal-147', background: 'var(--color-normal-106-a2)' }
}
