type Props = {
  shareUrl: string
  title: string
  description: string
  imageUrl: string
  buttonTitle: string
}

export default function shareAsKakaoTalk({
  title,
  description,
  imageUrl,
  shareUrl,
  buttonTitle,
}: Props) {
  if (window.Kakao) {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title,
        description,
        imageUrl,
        link: {
          mobileWebUrl: shareUrl,
          webUrl: shareUrl,
        },
      },
      buttons: [
        {
          title: buttonTitle,
          link: {
            mobileWebUrl: shareUrl,
            webUrl: shareUrl,
          },
        },
      ],
    })
  }
}
