import { LocaleEnum } from '@/types/locale'

export default function timeSince(date: Date, locale: LocaleEnum): string {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)

  let interval = seconds / 31536000
  if (interval > 1) {
    return Math.floor(interval) + (locale === LocaleEnum.ko ? '년 전' : ' years ago')
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + (locale === LocaleEnum.ko ? '개월 전' : ' months ago')
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + (locale === LocaleEnum.ko ? '일 전' : ' days ago')
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + (locale === LocaleEnum.ko ? '시간 전' : ' hours ago')
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + (locale === LocaleEnum.ko ? '분 전' : ' minutes ago')
  }
  return locale === LocaleEnum.ko ? '방금 전' : 'Just before'
}
