import * as React from 'react'

import type { NewsFieldsFragment } from '@/gql/generated-news'
import useLanguage from '@/utils/language/language-hook'
import { useGetByLocaleName } from '@/utils/language'

import ShareDropDown from '../../share-buttons/share-drop-down'

import StyledNewsShareButton from './styled'

type Props = {
  news: NewsFieldsFragment
}

const NewsShareButton = ({ news }: Props) => {
  const { t } = useLanguage('common')
  const getByLocaleName = useGetByLocaleName()

  return (
    <ShareDropDown
      shareUrl={news.dynamicLinkUrl || news.link}
      title={getByLocaleName(news.title)}
      description={getByLocaleName(news.description)}
      imageUrl={news.thumbnailUrl || 'https://images.therich.io/ogtag/home.png'}
      gaCategory="NEWS"
      gaAction="NEWS_ITEM-SHARE_CLICK"
      gaLabelPrefix={news.id}>
      <StyledNewsShareButton>{t('share')}</StyledNewsShareButton>
    </ShareDropDown>
  )
}

export default NewsShareButton
