import styled from 'styled-components'

const StyledNewsListItemAsset = styled.div`
  @media (min-width: 768px) {
    &.invisible {
      > *:not(button):nth-child(n + 3) {
        display: none;
      }
    }
  }

  > .more-view-button {
    margin-top: var(--spacing-legacy-6);
    padding: var(--spacing-legacy-4) 0;
  }
`

export default StyledNewsListItemAsset
