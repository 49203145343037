import styled from 'styled-components'

const StyledNewsListItemAsset = styled.div`
  display: inline-block;
  padding: var(--spacing-legacy-4) var(--spacing-legacy-6);
  border-radius: var(--border-radius-4);
  margin-top: var(--spacing-legacy-6);

  :not(:last-child) {
    margin-right: var(--spacing-legacy-10);
  }

  a {
    color: inherit;

    span {
      color: inherit;
    }

    .mark {
      word-break: break-all;
    }

    .change {
      margin-left: var(--spacing-legacy-5);
      display: inline-block;
    }
  }
`

export default StyledNewsListItemAsset
