import * as React from 'react'

import useLanguage from '@/utils/language/language-hook'
import copyToClipboard from '@/utils/browser/copy-to-clipborad'
import useToast from '@/utils/toast/hook'

import ShareButton from './share-button'

type Props = {
  onClick?: () => void
  logoOnly?: boolean
  shareUrl: string
}

const ShareURL = ({ shareUrl, onClick, logoOnly = true }: Props) => {
  const { t } = useLanguage('common')
  const { successToast } = useToast()
  const handleClick = React.useCallback(() => {
    copyToClipboard({ text: shareUrl })
    successToast(t('copiedURL'))
    onClick && onClick()
  }, [successToast, onClick, t, shareUrl])

  return (
    <ShareButton
      src="/images/shareUrl.png"
      alt="share URL"
      name={logoOnly ? t('copyURL') : undefined}
      onClick={handleClick}
    />
  )
}

export default ShareURL
