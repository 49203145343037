import * as React from 'react'

import useLanguage from '@/utils/language/language-hook'

import ShareButton from './share-button'

type Props = {
  onClick?: () => void
  logoOnly?: boolean
  shareUrl: string
}

const ShareFacebook = ({ shareUrl, onClick, logoOnly = true }: Props) => {
  const { t } = useLanguage('common')
  const handleClick = React.useCallback(() => {
    if (window.FB) {
      window.FB.ui(
        { method: 'share', href: shareUrl },
        // function (response) {},
      )
    } else {
      window.open('http://www.facebook.com/sharer/sharer.php?u=' + shareUrl)
    }
    onClick && onClick()
  }, [onClick, shareUrl])

  return (
    <ShareButton
      src="/images/shareFacebook@2x.png"
      alt="share Facebook"
      name={logoOnly ? t('facebook') : undefined}
      onClick={handleClick}
    />
  )
}

export default ShareFacebook
